// extracted by mini-css-extract-plugin
export var container = "ProfileSubscriptionsPage-module--container--3H9zU";
export var content = "ProfileSubscriptionsPage-module--content--24Ckf";
export var title = "ProfileSubscriptionsPage-module--title--1TNGJ";
export var goBackIcon = "ProfileSubscriptionsPage-module--goBackIcon--2MXQn";
export var box = "ProfileSubscriptionsPage-module--box--2rvIe";
export var currentlyMembership = "ProfileSubscriptionsPage-module--currentlyMembership--1kdGs";
export var upgrade = "ProfileSubscriptionsPage-module--upgrade--3dkyh";
export var membershipStatus = "ProfileSubscriptionsPage-module--membershipStatus--1xkLo";
export var freeMembership = "ProfileSubscriptionsPage-module--freeMembership--2bNOy";
export var statusContainer = "ProfileSubscriptionsPage-module--statusContainer--2cKzl";
export var checkBlueIcon = "ProfileSubscriptionsPage-module--checkBlueIcon--yHp8z";
export var statusText = "ProfileSubscriptionsPage-module--statusText--8CWTc";
export var vipMembershipBox = "ProfileSubscriptionsPage-module--vipMembershipBox--8A3E7";
export var boxTitle = "ProfileSubscriptionsPage-module--boxTitle--2U5Cq";
export var boxItem = "ProfileSubscriptionsPage-module--boxItem--X-fc2";
export var boxIcon = "ProfileSubscriptionsPage-module--boxIcon--3QWCY";
export var boxItemDescription = "ProfileSubscriptionsPage-module--boxItemDescription--2IyqB";
export var boxItemTitle = "ProfileSubscriptionsPage-module--boxItemTitle--2u_gC";
export var boxItemText = "ProfileSubscriptionsPage-module--boxItemText--_J4kM";
export var boxItemLink = "ProfileSubscriptionsPage-module--boxItemLink--1FtcX";
export var redNote = "ProfileSubscriptionsPage-module--redNote--29PCr";
export var button = "ProfileSubscriptionsPage-module--button--1IMwO";
export var info = "ProfileSubscriptionsPage-module--info--18YcA";
export var titleRow = "ProfileSubscriptionsPage-module--titleRow--13Q4a";
export var infoTitle = "ProfileSubscriptionsPage-module--infoTitle--1xu4-";
export var memberSince = "ProfileSubscriptionsPage-module--memberSince--2-Ibj";
export var card = "ProfileSubscriptionsPage-module--card--31pgw";
export var cardTitle = "ProfileSubscriptionsPage-module--cardTitle--2S5gP";
export var cardInfo = "ProfileSubscriptionsPage-module--cardInfo--109BO";
export var cardDate = "ProfileSubscriptionsPage-module--cardDate--365Xo";
export var cardSum = "ProfileSubscriptionsPage-module--cardSum--T7Zca";
export var footerContainer = "ProfileSubscriptionsPage-module--footerContainer--3Pxzt";