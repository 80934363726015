import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { trackingGA } from '@merchx-v2/helpers'
import { Button, Footer, Header, SEO } from 'components'
import { navigate } from 'utils/navigate'

import checkBlueIcon from 'assets/checkBlue.svg'
import conciergeIcon from 'assets/concierge.svg'
import goBackIcon from 'assets/goBack.svg'
import vipCardIcon from 'assets/vipCard.svg'
import vipGiftIcon from 'assets/vipGift.svg'

import { actionCreators as actionHomeCreators, selectMenu } from 'state/HomePage/reducer'
import { selectors as loginSelectors } from 'state/LoginPage/reducer'
import * as styles from './ProfileSubscriptionsPage.module.scss'

const ProfileSubscriptionsPage = ({
  loginState,
  menu,

  resetMenuFilters,
  setMenuCollection
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCollectionClicked = collection => {
    setMenuCollection(collection)
  }

  const handleReset = () => {
    resetMenuFilters()
  }

  const formatDateHandler = date => {
    const myDate = new Date(date)
    const month = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sep.', 'Oct.', 'Nov', 'Dec.'][myDate.getMonth()]
    const year = myDate.getFullYear()
    const day = myDate.getDate()
    const formattedDate = `${month} ${day}, ${year}`
    return formattedDate
  }

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken')

  if (!refreshToken) {
    return null
  }

  return (
    <div className={styles.container}>
      <SEO />
      <Header menu={menu} onCollectionClicked={handleCollectionClicked} onResetCollection={handleReset} />
      <div className={styles.content}>
        <div className={styles.title}>
          <img className={styles.goBackIcon} src={goBackIcon} alt='<' onClick={() => navigate('/profile')} />
          Subscriptions
        </div>

        <div className={styles.box}>
          <p className={styles.currentlyMembership}>Currently member of:</p>
          <div className={styles.membershipStatus}>
            <p className={styles.freeMembership}>Free Membership</p>
            <div className={styles.statusContainer}>
              <img className={styles.checkBlueIcon} src={checkBlueIcon} alt='&check;' />
              <p className={styles.statusText}>Active</p>
            </div>
          </div>
          <p className={styles.upgrade}>Available Upgrade:</p>
        </div>

        <div className={styles.vipMembershipBox}>
          <p className={styles.boxTitle}>Fan Made Fits VIP Membership</p>
          <div className={styles.boxItem}>
            <img className={styles.boxIcon} src={vipCardIcon} alt='' />
            <div className={styles.boxItemDescription}>
              <p className={styles.boxItemTitle}>VIP-Only Pricing - Zero profit pricing</p>
              <p className={styles.boxItemText}>Get all our products for what we get them for. That means more free shirts, $10 hoodies, $10 sweatshirts, and more!</p>
            </div>
          </div>

          <div className={styles.boxItem}>
            <img className={styles.boxIcon} src={conciergeIcon} alt='' />
            <div className={styles.boxItemDescription}>
              <p className={styles.boxItemTitle}>Concierge Service - No Limits</p>
              <p className={styles.boxItemText}>
                Want your own pictures or designs on our products? Want a new product we don’t yet stock? Want special bulk pricing? Just ask your concierge.
              </p>
            </div>
          </div>

          <div className={styles.boxItem}>
            <img className={styles.boxIcon} src={vipGiftIcon} alt='' />
            <div className={styles.boxItemDescription}>
              <p className={styles.boxItemTitle}>And So Much More!</p>
              <p className={styles.boxItemText}>
                See Full VIP Benefits{' '}
                <span className={styles.boxItemLink} onClick={() => navigate('/membership')}>
                  Here
                </span>
              </p>
            </div>
          </div>
          <div className={styles.button}>
            <Button value='Become VIP Now' loading={loginState.UIState.isLoading} customAction={() => navigate('/vip-signup')} />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.titleRow}>
            <div className={styles.infoTitle}>Recent Billing</div>
            <div className={styles.memberSince}>Member Since {new Date(loginState.membershipInfo?.activeFrom).getFullYear() || ''}</div>
          </div>

          {loginState.membershipInfo.recentPayments.length
            ? loginState.membershipInfo.recentPayments.map(item => {
                return (
                  <div>
                    <p>VIP Membership</p>
                    <div>
                      <p>{formatDateHandler(item.createdAt)}</p>
                      <p>Paid ${(item.total / 100).toFixed(2)}</p>
                    </div>
                  </div>
                )
              })
            : null}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  loginState: loginSelectors.selectLoginState(state)
})

const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSubscriptionsPage)
